import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";

import { ArrowLeft, ArrowsClockwise } from "@phosphor-icons/react";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import crypto from "crypto";
import posthog from "posthog-js";
import { useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import {
  PreventFlashOnWrongTheme,
  ThemeProvider,
  useTheme,
} from "remix-themes";

import NotFoundImage from "~/images/404.svg";
import ErrorImage from "~/images/500.svg";
import * as gtag from "~/lib/gtags.client";
import tailwind from "~/tailwind.css?url";

import Container from "./components/container";
import { FlexRow } from "./components/flex-row";
import { Button, buttonVariants } from "./components/ui/button";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import { Typography } from "./components/ui/typography";
import { authenticateUser } from "./lib/auth.server";
import { prisma } from "./lib/prisma.server";
import { themeSessionResolver } from "./lib/session.server";
import { cn, getLocale } from "./lib/utils";
import { CLOUDINARY_IMAGE_URL, DEFAULT_LANGUAGE } from "./utils/constants";
import { DASHBOARD_PATH } from "./utils/paths";

export const links: LinksFunction = () => [
  { href: tailwind, rel: "stylesheet" },
  { href: "https://fonts.gstatic.com", rel: "preconnect" },
  { href: "https://fonts.googleapis.com", rel: "preconnect" },
  {
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    rel: "stylesheet",
  },
  {
    href: "https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,1;1,9..144,100..900,0..100,1&display=swap",
    rel: "stylesheet",
  },
];

export const meta: MetaFunction = () => [{ title: "Conveo" }];

// Replace once we have a prisma DB & authenticator
export async function loader({ request }: LoaderFunctionArgs) {
  // Used by the useOptionalUser hook
  const user = await authenticateUser(request);

  const { getTheme } = await themeSessionResolver(request);

  const currentTeam =
    user == null
      ? undefined
      : (
          await prisma.role.findFirst({
            orderBy: { lastActivatedAt: "desc" },
            select: { team: { select: { asset: true, id: true, name: true } } },
            where: { userId: user.id },
          })
        )?.team;
  const otherTeams =
    user == null
      ? undefined
      : await prisma.team.findMany({
          select: { asset: true, id: true, name: true },
          where: {
            id: { not: currentTeam?.id },
            roles: { some: { userId: user.id } },
          },
        });

  return json({
    cspScriptNonce: crypto.randomBytes(16).toString("hex"),
    currentTeam,
    otherTeams,
    theme: getTheme(),
    user,
  });
}

export default withSentry(AppWithProviders);

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/api/set-theme">
      <App />
    </ThemeProvider>
  );
}

const gaTrackingId = "G-B4KDYGYLZ9";
const innerHTML = {
  __html: `
window.dataLayer = window.dataLayer ?? [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${gaTrackingId}', {
  page_path: window.location.pathname,
});
`,
};

export function App() {
  const location = useLocation();
  const {
    cspScriptNonce,
    theme: ssrTheme,
    user,
  } = useLoaderData<typeof loader>();
  const [posthogLoaded, setPosthogLoaded] = useState(false);
  const [theme] = useTheme();

  useEffect(() => {
    posthog.init("phc_AFfZITinYAbTEdaM6WaVox9YzSuWIL3vfI2mZvVJh6K", {
      api_host: "https://eu.posthog.com",
      capture_pageview: false, // because Remix renders both server and client side, we track pageviews manually
      loaded: () => {
        setPosthogLoaded(true);
      },
    });
  }, []);

  useEffect(() => {
    if (user != null && posthogLoaded) {
      posthog.identify(user.id, {
        email: user.email,
      });
    }
  }, [posthogLoaded, user]);

  useEffect(() => {
    if (posthogLoaded) {
      posthog.capture("$pageview");
    }
    gtag.pageview({ trackingId: gaTrackingId, url: location.pathname });
  }, [posthogLoaded, location.pathname]);

  return (
    <IntlProvider
      defaultLocale={getLocale(DEFAULT_LANGUAGE)}
      locale={getLocale(DEFAULT_LANGUAGE)}
      messages={undefined}
    >
      <html
        className={cn(theme, "h-full font-sans")}
        lang={getLocale(DEFAULT_LANGUAGE)}
      >
        <head>
          <meta charSet="utf-8" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Conveo" property="og:title" />
          <meta
            content="Experience the state of the art in qualitative research. Conveo’s AI-powered platform automates the full research process — from topic guide creation and participant recruitment, over conducting AI-led video and voice interviews to discovering deep, actionable insights."
            property="og:description"
          />
          <meta
            content={`${CLOUDINARY_IMAGE_URL}/f_auto,q_auto/pbh2ay2bsswqnv4uvuif`}
            property="og:image"
          />
          <PreventFlashOnWrongTheme
            nonce={cspScriptNonce}
            ssrTheme={Boolean(ssrTheme)}
          />
          <Meta />
          <Links />
        </head>
        <body className="smallScrollbar flex min-h-full flex-col antialiased">
          <>
            <script
              async
              nonce={cspScriptNonce}
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
              suppressHydrationWarning
            />
            <script
              async
              dangerouslySetInnerHTML={innerHTML}
              id="gtag-init"
              nonce={cspScriptNonce}
              suppressHydrationWarning
            />
          </>
          <TooltipProvider>
            <Outlet />
          </TooltipProvider>
          <ScrollRestoration nonce={cspScriptNonce} />
          <Toaster />
          <Scripts nonce={cspScriptNonce} />
        </body>
      </html>
    </IntlProvider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  let errorMessage = "Unknown Error";
  let errorImage = ErrorImage;

  if (isRouteErrorResponse(error)) {
    errorMessage = `${error.status} ${error.statusText}`;
    if (error.status === 404) {
      errorImage = NotFoundImage;
    }
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  const reload = useCallback(() => location.reload(), []);

  return (
    <IntlProvider
      defaultLocale={getLocale(DEFAULT_LANGUAGE)}
      locale={getLocale(DEFAULT_LANGUAGE)}
      messages={undefined}
    >
      <html className="h-full font-sans" lang={getLocale(DEFAULT_LANGUAGE)}>
        <head>
          <meta charSet="utf-8" />
          <title>Something went wrong | Conveo</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <Meta />
          <Links />
        </head>
        <body className="smallScrollbar flex min-h-full flex-col">
          <Container className="text-center">
            <img alt="Error" className="mx-auto size-80" src={errorImage} />

            <Typography as="h1" className="mb-4 mt-6" variant="h3">
              {errorMessage}
            </Typography>
            <Typography variant="mutedText">
              We’ve been notified about this error and are looking into it.
            </Typography>
            <FlexRow className="mt-6 justify-center gap-4">
              <Link
                className={buttonVariants({ variant: "secondary" })}
                to={DASHBOARD_PATH}
              >
                <ArrowLeft
                  aria-hidden="true"
                  className="-ml-1 mr-2"
                  size={16}
                />
                Back to dashboard
              </Link>
              <Button onClick={reload} variant="default">
                <ArrowsClockwise
                  aria-hidden="true"
                  className="-ml-1 mr-2"
                  size={16}
                />
                Reload
              </Button>
            </FlexRow>
          </Container>
          <Scripts />
        </body>
      </html>
    </IntlProvider>
  );
}
